(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tur/pay/assets/javascripts/account-services/player-deposit-suspended.js') >= 0) return;  svs.modules.push('/components/tur/pay/assets/javascripts/account-services/player-deposit-suspended.js');

'use strict';

const DEPOSITS_SUSPENDED = 'DEPOSITS_SUSPENDED';
const isPlayerDepositSuspendedAsync = async () => {
  try {
    const options = {
      method: 'GET',
      path: '/player/2/info?include=accountBalance,currentStatus'
    };
    const {
      response,
      status
    } = await svs.core.jupiter.callAsync(options);
    if (status && status === 'success') {
      var _response$currentStat;
      return (_response$currentStat = response.currentStatus) === null || _response$currentStat === void 0 ? void 0 : _response$currentStat.includes(DEPOSITS_SUSPENDED);
    }
    return false;
  } catch (error) {
    return false;
  }
};

async function playerDepositSuspended(_ref) {
  let {
    next,
    cancel
  } = _ref;
  const isDepositSuspended = await isPlayerDepositSuspendedAsync();
  if (isDepositSuspended) {
    svs.tur.apierrors.showErrorMessage({
      response: 5139,
      onConfirm: cancel
    });
  } else {
    next();
  }
}
svs.tur.pay.account_services.playerDepositSuspended = playerDepositSuspended;

 })(window);