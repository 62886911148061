(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tur/pay/assets/javascripts/account-services/player-session.js') >= 0) return;  svs.modules.push('/components/tur/pay/assets/javascripts/account-services/player-session.js');

'use strict';

function playerSession(_ref) {
  let {
    next,
    saveState,
    price,
    product,
    drawCount = 1,
    team
  } = _ref;
  if (!svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER)) {
    return saveState(() => svs.components.customer_login.login({
      returnNewCustomer: true,
      extraParams: {
        amount: price,
        product,
        weeks: drawCount,
        branding: product,
        team
      }
    }));
  }
  next();
}
svs.tur.pay.account_services.playerSession = playerSession;

 })(window);