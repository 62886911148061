(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tur/pay/assets/javascripts/init-pay.js') >= 0) return;  svs.modules.push('/components/tur/pay/assets/javascripts/init-pay.js');

'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
let paymentInProgress = false;
function addCalls(callsToAdd, calls, currentCall) {
  var _currentCall$fn$name, _currentCall$fn;
  svs.tur.pay.logger.debug("init-pay:addCalls - Calls added by ".concat((_currentCall$fn$name = (_currentCall$fn = currentCall.fn) === null || _currentCall$fn === void 0 ? void 0 : _currentCall$fn.name) !== null && _currentCall$fn$name !== void 0 ? _currentCall$fn$name : currentCall.name, ". Added calls: ").concat(callsToAdd.map(call => {
    var _call$fn$name, _call$fn;
    return (_call$fn$name = (_call$fn = call.fn) === null || _call$fn === void 0 ? void 0 : _call$fn.name) !== null && _call$fn$name !== void 0 ? _call$fn$name : call.name;
  }).join(', ')));
  callsToAdd = [...callsToAdd];
  callsToAdd.reverse();
  callsToAdd.forEach(call => {
    if (call && (typeof call === 'function' || typeof call.fn === 'function')) {
      calls.unshift(call);
    }
  });
}
function setup() {
  paymentInProgress = true;
  svs.utils.paramHandler.remove({
    key: 'pay'
  });
}
function cleanup() {
  paymentInProgress = false;
}







function initPay(_ref) {
  let {
    calls,
    cancel,
    saveState,
    done,
    error
  } = _ref;
  if (paymentInProgress) {
    return;
  }

  calls = [...calls];
  const cancelCallback = args => {
    cleanup();
    cancel === null || cancel === void 0 || cancel(args);
  };
  const doneCallback = args => {
    cleanup();
    done === null || done === void 0 || done(args);
  };
  const errorCallback = args => {
    cleanup();
    error === null || error === void 0 || error(args);
  };
  const saveStateCallback = onSaved => {
    const {
      value
    } = svs.utils.paramHandler.get({
      key: 'pay'
    });
    if (!value) {
      svs.utils.paramHandler.set({
        key: 'pay',
        value: 'true'
      });
    }
    if (typeof saveState === 'function') {
      saveState(onSaved);
    } else {
      onSaved();
    }
  };
  setup();
  const next = () => {
    const call = calls.shift();
    try {
      if (call) {
        if (typeof call === 'function') {
          const add = call();
          if (add && Array.isArray(add.calls)) {
            addCalls(add.calls, calls, call);
          }
          next();
        } else {
          var _call$in;
          call.fn(_objectSpread(_objectSpread({}, (_call$in = call.in) === null || _call$in === void 0 ? void 0 : _call$in.call(call)), {}, {
            next: args => {
              var _call$out;
              (_call$out = call.out) === null || _call$out === void 0 || _call$out.call(call, args);
              next();
            },
            cancel: cancelCallback,
            saveState: saveStateCallback
          }));
        }
      } else {
        doneCallback();
      }
    } catch (error) {
      var _call$fn$name2, _call$fn2;
      svs.tur.pay.logger.warn("Payment interrupted because of ".concat(error));
      svs.tur.pay.logger.debug("Payment interrupted at ".concat((_call$fn$name2 = call === null || call === void 0 || (_call$fn2 = call.fn) === null || _call$fn2 === void 0 ? void 0 : _call$fn2.name) !== null && _call$fn$name2 !== void 0 ? _call$fn$name2 : call === null || call === void 0 ? void 0 : call.name));
      errorCallback(error);
      svs.tur.pay.dialogs.generalError({
        cancel: () => cancelCallback()
      });
    }
  };
  next();
}
svs.tur.pay.initPay = initPay;

 })(window);