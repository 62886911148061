(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tur/pay/assets/javascripts/account-services/player-deposit-limit.js') >= 0) return;  svs.modules.push('/components/tur/pay/assets/javascripts/account-services/player-deposit-limit.js');

'use strict';

const isPlayersDepositLimitsSetAsync = async () => {
  try {
    const options = {
      method: 'GET',
      path: '/player/1/depositlimits'
    };
    const {
      response,
      status
    } = await svs.core.jupiter.callAsync(options);
    if (status && status === 'success') {
      return response.limitsIsSet;
    }
    return true;
  } catch (error) {
    return true;
  }
};

async function playerDepositLimits(_ref) {
  let {
    next,
    cancel
  } = _ref;
  const limitsIsSet = await isPlayersDepositLimitsSetAsync();
  if (limitsIsSet) {
    next();
  } else {
    svs.tur.apierrors.showErrorMessage({
      response: 6289,
      onConfirm: cancel
    });
  }
}
svs.tur.pay.account_services.playerDepositLimits = playerDepositLimits;

 })(window);